var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"header white primary--text",attrs:{"clipped-left":_vm.$vuetify.breakpoint.lgAndUp,"app":""}},[_c('v-app-bar-nav-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.updateDrawer()}}}),_c('v-toolbar-title',{staticClass:"ml-0 pl-4",staticStyle:{"width":"300px"}},[_c('span',{staticClass:"font-weight-bold text-h5 hidden-sm-and-down"},[_vm._v(" ThinkPro CMS ")]),(_vm.environment !== 'PRODUCTION')?_c('v-chip',{staticClass:"font-weight-bold rounded-lg ml-3",attrs:{"color":"red accent-2","dark":"","label":""}},[_vm._v(_vm._s(_vm.environment)+" ")]):_vm._e()],1),_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-black rounded-lg mr-4",attrs:{"color":"primary","outlined":""},on:{"click":_vm.openModalCustomer}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"18px"}},[_vm._v("mdi-account")]),_vm._v(" Thêm khách hàng ")],1),_c('v-btn',{staticClass:"font-weight-black rounded-lg mr-4",attrs:{"to":{ name: 'sale' },"color":"primary","outlined":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"18px"}},[_vm._v("mdi-storefront")]),_vm._v(" Bán hàng ")],1),_c('v-menu',{attrs:{"max-height":"350px","nudge-top":"-8px","offset-y":"","rounded":"lg","transition":"scroll-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"grey darken-1","icon":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"color":"red","content":"12","overlap":""}},[_c('v-icon',{attrs:{"size":"20px"}},[_vm._v("mdi-format-list-bulleted")])],1)],1)]}}])},[_c('v-list',_vm._l((_vm.activities),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""}},[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item)}})],1)}),1)],1),_c('v-menu',{attrs:{"max-height":"350px","nudge-top":"-8px","offset-y":"","rounded":"lg","transition":"scroll-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"grey darken-1","icon":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"color":"red","content":"16","overlap":""}},[_c('v-icon',{attrs:{"size":"20px"}},[_vm._v("mdi-bell")])],1)],1)]}}])},[_c('v-list',_vm._l((_vm.activities),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""}},[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item)}})],1)}),1)],1),_c('v-menu',{attrs:{"nudge-top":"-10px","offset-y":"","rounded":"lg","transition":"scroll-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey darken-1","icon":"","large":""}},'v-btn',attrs,false),on),[(_vm.user)?_c('v-avatar',{attrs:{"color":"deep-purple lighten-3","size":"28"}},[(_vm.user.avatar && _vm.user.avatar !== '')?_c('img',{attrs:{"alt":_vm.user.name,"src":_vm.user.avatar}}):_c('span',{staticClass:"text-h6 white--text font-weight-black",staticStyle:{"margin-bottom":"2px"}},[_vm._v(" "+_vm._s(_vm.user.name[0])+" ")])]):_vm._e()],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.accountMenu),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":item.link && item.link.name ? item.link : null,"link":""},nativeOn:{"click":function($event){item.link === 'logout' ? _vm.logout() : null}}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-body-1 font-weight-regular",domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }