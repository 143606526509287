<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
    class="header white primary--text"
  >
    <v-app-bar-nav-icon
      color="primary"
      @click="updateDrawer()"
    ></v-app-bar-nav-icon>

    <v-toolbar-title class="ml-0 pl-4" style="width: 300px">
      <span class="font-weight-bold text-h5 hidden-sm-and-down">
        ThinkPro CMS
      </span>
      <v-chip
        v-if="environment !== 'PRODUCTION'"
        class="font-weight-bold rounded-lg ml-3"
        color="red accent-2"
        dark
        label
        >{{ environment }}
      </v-chip>
    </v-toolbar-title>
    <v-spacer></v-spacer>

    <v-btn
      class="font-weight-black rounded-lg mr-4"
      color="primary"
      outlined
      @click="openModalCustomer"
    >
      <v-icon class="mr-2" size="18px">mdi-account</v-icon>
      Thêm khách hàng
    </v-btn>

    <v-btn
      :to="{ name: 'sale' }"
      class="font-weight-black rounded-lg mr-4"
      color="primary"
      outlined
    >
      <v-icon class="mr-2" size="18px">mdi-storefront</v-icon>
      Bán hàng
    </v-btn>
    <!-- Start: Activities Dropdown -->
    <v-menu
      max-height="350px"
      nudge-top="-8px"
      offset-y
      rounded="lg"
      transition="scroll-y-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="mr-2" color="grey darken-1" icon v-bind="attrs" v-on="on">
          <v-badge color="red" content="12" overlap>
            <v-icon size="20px">mdi-format-list-bulleted</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-for="(item, index) in activities" :key="index" link>
          <v-list-item-title v-html="item"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- End: Activities Dropdown -->
    <!-- Start: Notification Dropdown -->
    <v-menu
      max-height="350px"
      nudge-top="-8px"
      offset-y
      rounded="lg"
      transition="scroll-y-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="mr-2" color="grey darken-1" icon v-bind="attrs" v-on="on">
          <v-badge color="red" content="16" overlap>
            <v-icon size="20px">mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-for="(item, index) in activities" :key="index" link>
          <v-list-item-title v-html="item"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- End: Notification Dropdown -->
    <!-- Start: Account Dropdown -->
    <v-menu
      nudge-top="-10px"
      offset-y
      rounded="lg"
      transition="scroll-y-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn color="grey darken-1" icon large v-bind="attrs" v-on="on">
          <v-avatar v-if="user" color="deep-purple lighten-3" size="28">
            <img
              v-if="user.avatar && user.avatar !== ''"
              :alt="user.name"
              :src="user.avatar"
            />
            <span
              v-else
              class="text-h6 white--text font-weight-black"
              style="margin-bottom: 2px"
            >
              {{ user.name[0] }}
            </span>
          </v-avatar>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item
          v-for="(item, index) in accountMenu"
          :key="index"
          :to="item.link && item.link.name ? item.link : null"
          link
          @click.native="item.link === 'logout' ? logout() : null"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              class="text-body-1 font-weight-regular"
              v-text="item.text"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- Start: Account Dropdown -->
  </v-app-bar>
</template>

<script>
export default {
  props: {
    drawer: Boolean
  },
  data() {
    return {
      accountMenu: [
        {
          icon: "mdi-account-cog-outline",
          text: "Thiết lập tài khoản",
          link: { name: "account_account-setting" }
        },
        {
          icon: "mdi-logout-variant",
          text: "Đăng xuất",
          link: "logout"
        }
      ],
      activities: [
        `<a href="#">Nguyễn Mai</a> đã chuyển vào quỹ <strong>25M</strong> 5 phút trước`,
        `<a href="#">Nguyễn Mai</a> đã chuyển vào quỹ <strong>25M</strong> 5 phút trước`,
        `<a href="#">Nguyễn Mai</a> đã chuyển vào quỹ <strong>25M</strong> 5 phút trước`,
        `<a href="#">Nguyễn Mai</a> đã chuyển vào quỹ <strong>25M</strong> 5 phút trước`,
        `<a href="#">Nguyễn Mai</a> đã chuyển vào quỹ <strong>25M</strong> 5 phút trước`,
        `<a href="#">Nguyễn Mai</a> đã chuyển vào quỹ <strong>25M</strong> 5 phút trước`,
        `<a href="#">Nguyễn Mai</a> đã chuyển vào quỹ <strong>25M</strong> 5 phút trước`,
        `<a href="#">Nguyễn Mai</a> đã chuyển vào quỹ <strong>25M</strong> 5 phút trước`,
        `<a href="#">Nguyễn Mai</a> đã chuyển vào quỹ <strong>25M</strong> 5 phút trước`,
        `<a href="#">Nguyễn Mai</a> đã chuyển vào quỹ <strong>25M</strong> 5 phút trước`
      ],
      environment: process.env.VUE_APP_ENVIRONMENT
    };
  },
  computed: {
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("AUTHENTICATION/logout");
    },
    openModalCustomer() {
      this.$modal.show({ name: "modal-customer" });
    },
    updateDrawer() {
      this.$emit("updateDrawer", !this.drawer);
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1) !important;
}
</style>
